<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>组织架构</el-breadcrumb-item>
      <el-breadcrumb-item>岗位管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div class="oraRbox">
        <div class="orabox-left">
          <zm-tree-org
            :data="datas"
            collapsable
            @on-node-click="nodeClick"
            @on-contextmenu="onCont"
            :define-menus="define"
            :node-draggable="draggable"
            :props="ZMprops"
            :default-expand-level="1"
          ></zm-tree-org>
          <div class="hinttitle-right">
            <el-button type="primary" class="verbal brns"></el-button>
            <div class="verbal">组织</div>
            <el-button type="success" class="verbal brns"></el-button>
            <div class="verbal">岗位</div>
          </div>
        </div>
        <div class="orabox-right">
          <!-- 岗位模块 -->
          <div class="right-org" v-if="postflag">
            <div class="righttop">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item class="texts" v-for="(item, index) in this.postlink" :key="index">{{ item.name }}</el-breadcrumb-item>
                <!-- <el-breadcrumb-item class="texts">{{ rightname }}</el-breadcrumb-item> -->
              </el-breadcrumb>
            </div>
            <el-button type="primary" plain @click="Roleclick">岗位管理</el-button>

            <el-table :data="bindinguser" style="width: 90%">
              <el-table-column prop="work_no" label="工号" width="100" align="center"></el-table-column>
              <el-table-column prop="user_name" label="姓名" width="120" align="center"></el-table-column>
              <el-table-column prop="type_name" label="职级" align="center"></el-table-column>
              <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
            </el-table>
            <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
          </div>
        </div>
      </div>
    </el-card>

    <!-- 岗位绑定弹窗 -->
    <Role :roleVisible="roleVisible" :rolename="rolename" :roleId="roleId" @roleClose="roleClose" @Emits="Emits"></Role>
  </div>
</template>

<script>
import { Organtree, Orgproject, Orgcompany, Orgprojectlist, Orgeditproject, Orgcontractlist, Orgbind, Orgtypelist, Orguserlist, Orgdelete } from '../../../api/personnel.js'
import Fenye from '../../../components/Fenye.vue'
import Role from './components/role.vue'
export default {
  components: {
    Role,
    Fenye
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      postlink: [],
      Itemlink: [],
      node_id: '',
      roleId: null,
      righttitle: '',
      rolename: '',
      roleVisible: false,
      itemflag: false,
      postflag: false,
      typelist: [],
      title: '',
      rightname: '',
      datas: {},
      define: [{ name: '复制文本', command: 'copy' }],
      draggable: false,
      ZMprops: { id: 'id', label: 'name' },
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      bindinguser: [],
      Nform: {
        name: '',
        company_id: '',
        node_id: '',
        desc: '',
        id: ''
      },
      options: [],
      constID: '', // 项目id
      pageData: {
        page: 1,
        size: 10,
        count: 0
      }
    }
  },
  mounted() {
    this.Organtrees()
  },
  methods: {
    // 节点点击事件
    nodeClick(e, data) {
    //   console.log(data)
      this.Nform.node_id = ''
      if (data.type === 'node') {
        this.title = '组织'
        this.$message({
          message: '请选择岗位进行调整',
          type: 'warning'
        })
        // this.Itemlink = data.link
        // let arr = data.link
        // let userNames = arr
        //   .map(item => {
        //     return item.name
        //   })
        //   .join('>')
        // console.log(userNames)
        // this.righttitle = userNames
        // this.Nform.node_id = data.node_id
        // this.node_id = data.node_id
        // this.itemflag = true
        // this.postflag = false
        // this.Orgprojectlists(data.node_id)
      } else {
        this.title = '岗位'
        let arr = data.link
        let userNames = arr
          .map(item => {
            return item.name
          })
          .join('>')
        // console.log(userNames)
        this.postlink = data.link
        this.itemflag = false
        this.postflag = true
        this.rolename = userNames
        this.roleId = Number(data.id)
        this.Orguserlistsok(data.id)
      }
      this.rightname = data.name
    },

    // 右键
    onCont(command) {
      // console.log(command)
    },

    // 岗位按钮
    Roleclick() {
      this.roleVisible = true
    },

    // 岗位添加弹窗关闭
    roleClose() {
      this.roleVisible = false
    },

    Emits(e) {
      this.Orguserlistsok(e)
      // console.log('触发拉取信息', e)
    },

    ///////////////////请求区//////////////////

    // 获取组织树
    async Organtrees() {
      const { data } = await Organtree()
      // console.log(data.data)
      if (data.data.length === 0) {
        this.datas = {}
        this.disabled = true
      } else {
        this.datas = data.data
        let Objs = data.data
        this.disabled = false
        this.getChild(Objs, '数据')
      }
    },

    // 递归
    getChild(valueArr, type) {
      //   console.log(valueArr, type)
      let level = 0
      let maxLevel = 0
      const loop = (data, level) => {
        // console.log(data, 'data')
        data.children.forEach(item => {
          item.level = level
          if (level > maxLevel) {
            maxLevel = level
          }
          // console.log(item)
          if ('children' in item) {
            if (item.type === 'node') {
              // item.style = { backgroundColor: '#409EFF', color: 'white', borderRadius: ' 9px' , maxHeight: '50px'}
              item.style = { backgroundColor: '#409EFF', color: 'white', borderRadius: ' 9px' }
            } else {
              // item.style = { backgroundColor: '#85CE61', color: 'white', borderRadius: ' 9px' , maxHeight: '50px'}
              item.style = { backgroundColor: '#85CE61', color: 'white', borderRadius: ' 9px'}
            }
            if (item.children.length > 0) {
              loop(item, level + 1)
            }
            if (item.children.length == 0) {
              item.children = null
            }
          }
        })
      }
      loop(valueArr, 1)
    },

    // 人员已绑定
    async Orguserlistsok(id) {
      const { data } = await Orguserlist({ role_id: id, waiting: 0, check_all: 1, ...this.pageData })
      // console.log(data, '已绑定')
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
      this.bindinguser = data.data.list
    },

    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Orguserlistsok()
    },

    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Orguserlistsok()
    }
  }
}
</script>

<style  lang="less">
.oraRbox {
  box-sizing: border-box;
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: space-around;
  .orabox-left {
    width: 68%;
    height: 100%;
    background-color: #f2f2f2;
    position: relative;
    .hinttitle-right {
      display: flex;
      align-items: center;
      width: 23%;
      position: absolute;
      top: 0%;
      right: 0%;
      .verbal {
        margin-left: 2%;
      }
      .brns {
        margin-left: 5%;
      }
    }
  }
  .orabox-right {
    width: 32%;
    height: 100%;
    border: 1px solid #dddddd !important;
    box-shadow: 2px 2px 5px 2px #dddddd;
    .texts {
      font-size: 16px !important;
      color: #000 !important;
    }
    .righttop {
      width: 100%;
      height: 5vh;
      box-sizing: border-box;
      // border: 1px solid #000;
      padding: 7px 10px;
    }
    .right-org {
      box-sizing: border-box;
      padding: 5px 10px;
      // border: 1px solid #000;
      width: 100%;
      height: 100%;
    }
  }
}
.tree-org-node__text {
  padding: 0px 15px !important;
}
.tree-org-node__content {
  background-color: #409eff;
  color: white;
  border-radius: 9px !important;
}
.NoOk {
  margin-left: 10%;
}
.dialogOrg {
  width: 100%;
  // border: 1px solid #000;
  box-sizing: border-box;
  justify-content: space-around;
  display: flex;
  .dialogOrg-left {
    width: 48%;
  }
  .dialogOrg-right {
    width: 48%;
    .shai {
      width: 100%;
      // border: 1px solid #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>